footer {
    color: #fff;
    padding-top: 50px;
    padding-bottom: 40px;
    background: #1ebad7;
    background-image: linear-gradient(to right, #1ebad7, #1fd1af);
    h4 {
        color: inherit;
        font-weight: $normal;
    }
    .container {
        @include flexbox;
        @include flexJustify(space-between, justify);
        padding: 0;
        >div {
            @include flexItem(1 1 33%);
        }
    }
    @media (max-width: $breakpointMD - 1) {
        .row {
            margin: 0;
        }
        .container {
            width: 100%;
            @include flexbox(column);
            >div {
                @include flexItem;
            }
        }
    }
}

.footerLogo {
    margin-bottom: 15px;
    .img-fluid {
        max-width: 220px;
    }
    @media (max-width: $breakpointMD - 1) {
        text-align: center;
        margin-bottom: 25px;
    }
}

.socialMedias {
    @media (max-width: $breakpointMD - 1) {
        @include flexJustify;
    }
}

.socialMedias__logo {
    a {
        height: 25px;
        width: 25px; // padding: 0 20px;
        margin: 0 10px;
        opacity: 0.7;
        @include flexbox;
        @include flexJustify;
        @include flexAlign;
        &:hover {
            opacity: 1;
        }
        @media (max-width: $breakpointMD - 1) {
            height: 22px;
            width: 22px; // padding: 0 10px;
            margin: 0 10px;
        }
    }
    img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
    }
}

.references {
    background: #1ebad7;
    background-image: linear-gradient(to right, lighten(#1ebad7, 10%), lighten(#1fd1af, 10%));
    padding-top: 20px;
    padding-bottom: 40px;
    margin-bottom: -40px;
    @media (min-width: $breakpointMD) {
        background-image: none;
        background: transparent;
        h4 {
            margin-bottom: 30px;
        }
    }
}

// .references__logos,
// .ratings {
//     @extend .centered;
// }
.references__logos {
    @include flexJustify(space-around, justify);
}

@media (max-width: $breakpointMD - 1) {
    .col {
        text-align: center;
    }
    .references__cards {
        max-width: 250px;
    }
}

.ratings {
    @include flexAlign(stretch);
    margin-bottom: 40px;
    @media (max-width: $breakpointMD - 1) {
        @include flexJustify;
        .col {
            @include flexItem;
            width: auto;
        }
    }
}

.ratings__rating {
    @include flexbox(column); // @include flexAlign(stretch);
    @include flexAlign(center);
    @include flexJustify;
    text-align: left;
    font-weight: $normal;
    line-height: 1.2;
}

.ratings__mark {
    font-size: 1.625rem;
}

.ratings__logo {
    @include flexbox;
    @include flexAlign(center);
    @include flexJustify(space-around);
    position: relative;
    &:after {
        content: '';
        position: absolute;
        background: rgba(#fff, 0.25);
        display: block;
        width: 1px;
        margin: 0 auto;
        top: 2px;
        bottom: 2px;
        right: 0px;
    }
    img {
        margin: 0 5px;
        @include flexItem(1 1 50%);
        max-width: 40px;
    }
    @media (max-width: $breakpointMD - 1) {
        padding-right: 10px !important;
        margin-right: 20px;
        img {
            margin: 0 10px;
        }
    }
}

// .ratings__separator {
//     padding: 0 5px 0 0 !important;
//     div {
//         background: #fff;
//         display: block;
//         min-height: 100%;
//         width: 1px;
//         margin: 0 auto;
//     }
//     @media (max-width: $breakpointMD - 1) {
//         padding: 0 15px 0 0 !important;
//     }
// }
.ratings__text {
    font-size: 0.875rem;
}

.informationsListContainer {
    margin: 0 30px;
    @include flexbox;
    @include flexJustify(center);
    @media (min-width: $breakpointMD) {
        padding-top: 20px;
    }
    @media (max-width: $breakpointMD - 1) {
        margin: 50px 0;
        padding: 0 20px;
        display: block;
    }
}

.informationsList {
    @include flexbox(column);
    @include flexAlign(stretch);
    padding: 0;
    margin: 0;
    list-style-type: none;
    @media (max-width: $breakpointMD - 1) {
        margin: 10px 0;
        &.collapse {
            display: none;
            &.show {
                display: -ms-flexbox;
                display: flex;
            }
        }
    }
}

.informationsListHeader,
.informationsList__item {
    a {
        @include flexbox;
        @include flexJustify(space-between, justify);
        color: #fff;
        text-decoration: none;
    }
}

@media (min-width: $breakpointMD) {
    .informationsList__item {
        a:hover {
            text-decoration: underline;
        }
    }
}

.informationsListHeader {
    margin: 0px;
    margin-bottom: 30px;
    >span {
        display: none;
    }
    a.collapsed {
        i.fa.fa-long-arrow-down {
            transform: rotate(-90deg);
        }
    }
    @media (min-width: $breakpointMD) {
        margin-bottom: 30px;
        a {
            display: none;
        }
        >span {
            display: inline;
        }
    }
}