.carousel {
    margin: 30px 0 50px;
}

.carousel-controls {
    @include flexbox;
    @include flexAlign;
    margin-top: 20px;
}

a[class*="carousel-control-"],
.carousel-indicators {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    @include flexItem(1 1 33%);
}

.carousel-indicators {
    @include flexAlign;
    padding: 0;
    margin: 0;
    li {
        max-width: 7px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #d0d0d0;
        margin: 0 10px;
        &.active {
            background: #1ebad6;
        }
    }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-image: none;
    .fa {
        color: $primaryColor;
    }
}

.carousel-item {
    .d-block {
        padding: 0 20px;
        margin: 0 auto;
    }
}