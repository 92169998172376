.navbar {
    padding: 0;
    background: #f0f0f0;
}

.navbar-nav {
    @include flexAlign;
}

.navbar-toggleable-sm {
    .navbar-nav {
        height: 100%;
        .nav-item {
            height: 100%;
            .nav-link {
                position: relative;
                height: 100%;
                &.active {
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 2px;
                        background-image: linear-gradient(to right, #1ebad7, #1fd1af);
                    }
                }
                @media (min-width: $breakpointMD) {
                    @include flexbox;
                    @include flexAlign;
                }
            }
        }
    }
}

.navbar-toggler {
    @include flexbox;
    @include flexJustify(space-between, justify);
    @include flexAlign;
    padding: 0 0 0 10px;
    border: none;
    width: 100%;
}

.navbar-brand {
    @include flexItem;
    height: 45px;
    margin-right: auto;
    padding: 0;
    @include flexbox;
    @include flexAlign;
}

.navbar-toggler__buttons {
    @include flexItem;
    @include flexbox;
}

.menuButton,
.menuBuyNow {
    @include flexItem;
    height: 45px;
    width: 45px;
    display: block;
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
}

.menuBuyNow {
    background-image: linear-gradient(to right, #1ebad7, #1fd1af);
    color: #fff;
    line-height: 45px;
    text-align: center;
}

.menuButton {
    @include flexbox;
    @include flexAlign;
    @include flexJustify;
    >span {
        display: block;
        border: 1px solid #9c9c9c;
        border-radius: 50%;
        line-height: 23px;
        height: 27px;
        width: 27px;
        text-align: center;
        i {
            color: #000;
            font-size: 1rem;
            position: relative;
            top: 1px;
        }
    }
}

.navbar.sticky-top {
    .navbar-collapse {
        >.container {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            max-width: 100%;
            @media (min-width: 992px) {
                width: 960px;
            }
            @media (max-width: $breakpointMD - 1) {
                .navbar-brand,
                .form-inline {
                    display: none;
                }
                .navbar-nav {
                    width: 100%;
                    .nav-item {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
    form {
        margin: 0 !important;
        @include flexbox;
        @include flexAlign;
        height: 100%;
        a {
            height: 100%;
            padding: 0 30px;
            @include flexbox;
            @include flexAlign; // line-height: 45px;
            background-image: linear-gradient(to right, #1ebad7, #1fd1af);
            color: #fff;
            border-radius: 0;
        }
    }
}

.navbar-light .navbar-nav .nav-link {
    color: #4d4d4d;
    text-align: center;
}

.navbar-light .navbar-nav .open>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.open,
.navbar-light .navbar-nav .nav-link.active {
    color: #000;
}

.nav-link {
    font-weight: $semibold;
    font-size: 0.875rem;
    @media (max-width: $breakpointMD - 1) {
        font-size: 0.875rem;
    }
}

.navbar--top {
    display: none;
    width: 100%;
    background: rgba(250, 250, 250, 0.5);
    box-shadow: 0px 40px 40px rgba(250, 250, 250, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px 0px 0;
    @media (min-width: $breakpointMD) {
        @include flexbox;
    }
    >.container {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        max-width: 100%;
        @media (min-width: 992px) {
            width: 960px;
        }
    }
    .navbar-nav {
        @include flexbox;
    }
    .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
        text-align: center;
    }
    .menuBuyNowButton {
        border-radius: 40px;
        border: 5px solid rgba(30, 186, 215, 0.3);
        height: 50px;
        display: inline-block;
        @include flexbox;
        a {
            font-weight: $semibold;
            background: #1ebad7;
            background-image: linear-gradient(to right, #1ebad7, #1fd1af);
            line-height: 40px;
            border: none;
            border-radius: 30px;
            color: #fff;
            padding: 0 30px;
            .fa {
                margin-right: 20px;
            }
        }
    }
}