body {
    position: relative;
    @media (max-width: $breakpointMD - 1) {
        padding-bottom: 45px;
    }
}

section {
    padding-top: 25px;
    padding-bottom: 25px;
}

main {
    padding-top: 20px;
}

.sticky-top {
    position: -webkit-sticky;
    position: sticky;
}

.logo_header {
    width: 110px;
}

#landing {
    @include flexbox;
    @include flexAlign;
    @include flexJustify;
    min-height: 100vh;
    background: url("../img/landing.jpg") no-repeat;
    background-position: 50%;
    background-size: cover;
    position: relative;
    padding-top: 100px;
}

.landingContent {
    color: #fff;
    font-weight: $normal;
    font-size: 1.375rem;
    text-align: center;
    padding: 0 15px;
    max-width: 100%;
    @include flexbox(column);
    @include flexAlign;
    @include flexJustify;
    @include flexItem;
    @media (min-width: $breakpointMD) {
        max-width: 50%;
    }
    h4 {
        color: inherit;
        font-size: 2.250rem;
    }
    img {
        margin-bottom: 30px;
    }
    p {
        max-width: 100%;
        display: none;
        @media (min-width: $breakpointMD) {
            display: block;
        }
    }
    img.scrollIcon {
        margin-top: 30px;
        width: 30px;
    }
    img.drop_logo {
        width: 120px;
    } // mobile
    @media (max-width: $breakpointMD - 1) {
        img.drop_logo {
            width: 60px;
        }
        h4 {
            font-size: 1.714rem;
        }
    }
}

.mobileLogoHeader {
    @include flexbox;
    @include flexJustify;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    img {
        width: 165px;
        height: 40px;
    }
    @media (min-width: $breakpointMD) {
        display: none;
    }
}

img.calc_small {
    display: inline-block;
    margin: 0;
    margin-right: 8px;
    width: 13px;
    position: relative;
    top: -2px;
}

img.downloadico {
    display: inline-block;
    margin: 0;
    margin-right: 10px;
    width: 25px;
    position: relative;
    top: -2px;
}

.dotted_bg {
    position: absolute;
    top: -30%;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
}

.downloadSection {
    margin-bottom: 50px;
    @media (max-width: $breakpointMD - 1) {
        .dotted_bg {
            top: 0%;
        }
    }
}

.rightSidePicture {
    margin-bottom: 50px;
    overflow: hidden;
    @media (max-width: $breakpointMD - 1) {
        height: 100%;
        margin: 0 0 0 -50px;
        @include flexbox;
        @include flexAlign;
    }
    img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
    }
}

.mb50 {
    margin-bottom: 50px;
}

.mt50 {
    margin-top: 50px;
}

.container--demoPicture {
    @media (max-width: $breakpointMD - 1) {
        width: auto;
    }
}

.demoPicture {
    margin: 0px -15px 30px;
    overflow: hidden;
    img {
        margin-left: -33%;
        width: 170%;
        margin-top: -5%;
        margin-bottom: -16%;
    }
    @media (min-width: $breakpointMD) {
        img {
            margin: 0;
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
}

.show-md {
    display: none;
    @media (min-width: $breakpointMD) {
        display: block;
    }
}

.hide-md {
    display: block;
    @media (min-width: $breakpointMD) {
        display: none;
    }
}

.text-center-md {
    @media (min-width: $breakpointMD) {
        text-align: center;
    }
}

.buyNowBar {
    z-index: 1040; // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    a {
        display: block;
        background: #1ebad7;
        background-image: linear-gradient(to right, #1ebad7, #1fd1af);
        height: 45px;
        line-height: 45px;
        font-size: 14px;
        color: #fff;
        text-align: center;
        font-weight: $semibold;
        text-decoration: none !important;
        @media (max-width: $breakpointMD - 1) {
            font-size: 1.357rem;
        }
    }
    @media (min-width: $breakpointMD) {
        display: none;
    }
}

.carouselColumn {
    @media (max-width: $breakpointMD - 1) {
        @include flexOrder(2);
    }
}

.dasProduktColumn {
    @media (max-width: $breakpointMD - 1) {
        @include flexOrder(1);
    }
}

#backToTop {
    position: fixed;
    background-image: linear-gradient(to right, #1ebad7, #1fd1af);
    height: 30px;
    width: 30px;
    bottom: 60px;
    right: 15px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    z-index: 1000; // display: none;
    @include flexbox;
    @include flexAlign;
    @include flexJustify;
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: #fff;
    }
    &.hide {
        display: none;
    }
    i {
        position: relative;
        top: -1px; // position: relative;
        // top: 1px;
    }
}
