.customOl {
    margin-top: 130px;
}

.customOl__header {
    font-weight: $semibold;
    font-size: 1.125rem;
    @include flexbox;
    @include flexAlign(baseline);
    @media (max-width: $breakpointMD - 1) {
        font-size: 1rem;
    }
}

.customOl__counter {
    font-size: 1.750rem;
    margin-right: 5px;
    @media (max-width: $breakpointMD - 1) {
        font-size: 1.857rem;
    }
}

.customOl__text {
    @media (max-width: $breakpointMD - 1) {
        font-size: 0.857rem;
    }
}