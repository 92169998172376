.infoLogos {
    @include flexbox;
    @include flexWrap;
    padding: 0;
    >div[class*="col"] {
        &:first-of-type {
            border-bottom: 1px solid #eeeeee;
            border-right: 1px solid #eeeeee;
        }
        &:nth-child(2) {
            border-bottom: 1px solid #eeeeee;
        }
        &:nth-child(3) {
            border-right: 1px solid #eeeeee;
        }
    }
    @media (min-width: $breakpointMD) {
        >div[class*="col"] {
            border-top: 1px solid #eeeeee;
            border-bottom: none !important;
            &:nth-child(2) {
                border-right: 1px solid #eeeeee;
            }
        }
    }
}

.infoLogos__container {
    font-weight: $semibold;
    text-align: center;
    padding: 30px 0;
    color: $secondaryColor;
    font-size: 1.250rem;
    @include flexbox(column);
    @include flexAlign;
    @include flexJustify;
    .gradientText {
        max-width: 100%;
    }
    @media (max-width: $breakpointMD - 1) {
        font-size: 1rem;
    }
}

.infoLogos__img {
    margin-bottom: 10px;
    .img-fluid {
        max-width: 50%;
    }
}