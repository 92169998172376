html {
    font-size: 16px;
    @media (max-width: $breakpointMD - 1) {
        font-size: 14px;
    }
}

body {
    font-size: 1rem;
    color: $primaryColor;
    font-weight: $light;
    font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
    margin: 0;
    color: $secondaryColor;
}

h2 {
    font-size: 2.625rem;
    font-weight: $semibold;
    margin-bottom: 30px;
    @media (max-width: $breakpointMD - 1) {
        font-size: 2.214rem;
    }
}

h3 {
    font-size: 2rem;
    font-weight: $light;
    @media (max-width: $breakpointMD - 1) {
        font-size: 1.643rem;
    }
}

h4 {
    font-size: 1.375rem;
    font-weight: $semibold;
    margin-bottom: 10px;
    @media (max-width: $breakpointMD - 1) {
        font-size: 1.286rem;
    }
    &.bigH4 {
        font-size: 1.625rem;
        @media (max-width: $breakpointMD - 1) {
            font-size: 1.286rem;
        }
    }
    &.biggerH4 {
        font-size: 1.750rem;
    }
    .h4BigDigits {
        margin-right: 8px;
        font-size: 2.813rem;
        @media (max-width: $breakpointMD - 1) {
            font-size: 1.375rem;
        }
    }
}

a {
    color: $secondaryColor;
}

button:focus {
    outline: none;
}

.gradientText {
    color: #1fc5c3;
    @include gradient-text('linear-gradient(to right,#1ebad7, #1fd1af)', 'light');
}