.accordionHeading {
    @extend .gradientText;
}

.accordionStatusIcon {
    @include flexItem;
    .fa-long-arrow-up {
        display: block;
        color: #1fd1af;
    }
    .fa-long-arrow-down {
        display: none;
        color: $secondaryColor;
    }
}

#accordion {
    .card {
        border: none;
        border-top: 1px solid #eee;
        border-radius: 0;
    }
    .card-header {
        border: none;
        background: transparent;
        a {
            @include flexbox;
            @include flexJustify(space-between, justify);
            text-decoration: none;
            font-size: 1.375rem;
            font-weight: $semibold;
            &.collapsed {
                .accordionHeading {
                    color: #4d4d4d;
                    &:before {
                        content: none;
                    }
                }
                .accordionStatusIcon {
                    .fa-long-arrow-up {
                        display: none;
                    }
                    .fa-long-arrow-down {
                        display: block;
                    }
                }
            }
            @media (max-width: $breakpointMD - 1) {
                font-size: 1.286rem;
            }
        }
    }
}