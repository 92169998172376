/* FLEXBOX */

@mixin flexbox($direction: row) {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: $direction;
    flex-direction: $direction;
}

// wrap flex items
@mixin flexWrap($wrap: wrap) {
    -ms-flex-wrap: $wrap;
    flex-wrap: $wrap;
}

// center horizonzally
@mixin flexJustify($alignment: center, $MSalignment: $alignment) {
    -ms-flex-pack: $MSalignment;
    justify-content: $alignment;
}

// center vertically
@mixin flexAlign($alignment: center, $MSalignment: $alignment) {
    -ms-flex-align: $MSalignment;
    align-items: $alignment;
}

// flex item : default is no shrink, no grow
@mixin flexItem($value: 0 0 auto) {
    -ms-flex: $value;
    flex: $value;
}

// flex order
@mixin flexOrder($value) {
    -ms-flex-order: $value;
    order: $value;
}

// gradient-text
@mixin gradient-text($gradient, $bg: 'light') {
    @supports(mix-blend-mode: lighten) {
        display: inline-block;
        position: relative;
        overflow: hidden;
        padding: 1px;
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            background: unquote($gradient);
            pointer-events: none;
        }
        @if ($bg=='light') {
            color: #000;
            background: #fff;
            mix-blend-mode: multiply;
            &::before {
                mix-blend-mode: screen;
            }
        }
        @else {
            color: #fff;
            background: #000;
            mix-blend-mode: lighten;
            &::before {
                mix-blend-mode: multiply;
            }
        }
    }
}