.picturePreview {
    display: block;
    overflow: hidden;
    position: relative;
    min-height: 100vh;
    margin: 50px -15px;
}

.picturePreview__img {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    max-height: 100%;
    width: auto;
}

.picturePreview__text {
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 40px;
    color: $primaryColor;
    .fa {
        font-size: 18px;
    }
}

#picturePreviewModal {
    .modal-body {
        overflow: auto;
        img {
            max-width: 100%;
            height: auto;
        }
    }
}