.downloadPic {
    width: 60px;
    margin-bottom: 10px;
}

.downloadText {
    text-transform: uppercase;
    color: #bfbfbf;
    font-size: 0.875rem;
    margin-bottom: 20px;
    @media (max-width: $breakpointMD - 1) {
        font-size: 0.714rem;
    }
}

.downloadButton,
.calculatorLink {
    border-radius: 40px;
    border: 5px solid rgba(30, 186, 215, 0.3);
    height: 50px;
    display: inline-block;
    @include flexbox;
    a {
        font-weight: $semibold;
        font-size: 0.9rem;
        background: #1ebad7;
        background-image: linear-gradient(to right, #1ebad7, #1fd1af);
        line-height: 40px;
        border: none;
        border-radius: 30px;
        color: #fff;
        padding: 0 25px;
        text-decoration: none;
        .fa {
            margin-right: 20px;
        }
    } // @media (max-width: $breakpointMD - 1) {
    //     a {
    //         font-size: 11px;
    //     }
    // }
}

.calculatorLink a {
    // width: 325px;
}