.columned {
    @include flexbox(column);
}

.centered {
    @include flexJustify;
    @include flexAlign;
    text-align: center;
}

.flexCenter {
    @include flexbox;
    @extend .centered;
}