#calculatorModal {
    .modal-dialog {
        max-width: none;
        margin: 0;
    }
    .modal-dialog-wrapper {
        height: 100%;
        @include flexbox(column);
        @include flexJustify(space-between, justify);
        @include flexAlign;
    }
}

.displaysCalculatorModal {
    .modal-backdrop {
        background-image: linear-gradient(to right, #1ebad7, #1fd1af);
        &.show {
            opacity: 0.9;
        }
    }
}

.calculatorModal-header {
    @include flexbox;
    @include flexJustify(space-between, justify);
    @include flexAlign;
    width: 90%;
    margin: 25px auto;
}

.calculatorModal-header__close {
    .close {
        text-shadow: none;
        color: #fff;
        font-size: 35px;
        opacity: 0.9;
        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}

.calculatorModal-body,
.mobileCalculatorModal-body {
    background: #fff;
    border-radius: 5px;
    display: none;
}

.calculatorModal-body {
    width: 860px;
    max-width: 80%;
    @media (min-width: $breakpointMD) {
        display: block;
    }
}

.mobileCalculatorModal-body {
    width: 90%;
    text-align: center;
    padding-bottom:20px;
    @media (max-width: $breakpointMD - 1) {
        display: block;
        margin-bottom: 30px;
    }
}

.calculatorModal-heading,
.mobileCalculatorModal-heading {
    @include flexbox;
    @include flexJustify;
    @include flexAlign;
    font-weight: $semibold;
    font-size: 1.375rem;
    padding: 20px 0 40px;
    color: #3f3f3f;
    img {
        width: 16px;
        top: 0;
        margin-right:5px;
    }
}

.mobileCalculatorModal-heading {
    text-align: initial;
    padding-bottom: 30px;
    font-size: 1.143rem;
}

.mobileCalculatorModal-content{
  text-align: initial;
}

.calculatorModal-inputs {
    @include flexbox;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
}

.mobileCalculatorModal-inputs {
    border-top: 1px solid #dddddd;
}

.mobileCalculatorModal-input {
    @include flexbox;
    border-bottom: 1px solid #dddddd;
}

.mobileCalculatorModal-input__valueLabel {
    // @include flexItem;
    @include flexbox;
    @include flexAlign;
    margin: 0 20px;
}

.calculatorModal-input {
    @include flexItem(1 1 33%);
    border-left: 1px solid #dddddd;
    @include flexbox;
    @include flexJustify;
    position: relative;
    padding: 35px 0 20px;
    &:first-of-type {
        border-left: none;
    }
}

.calculatorModal-input__img,
.mobileCalculatorModal-input__img {
    padding: 0 5px;
    background: #fff;
    img {
        max-height: 100%;
        max-width: 100%;
        display: block;
        margin: auto;
    }
}

.calculatorModal-input__img {
    height: 55px;
    width: 65px;
}

.mobileCalculatorModal-input__img {
    height: 35px;
    width: 35px;
    @include flexbox;
    margin: 20px 10px;
}

.calculatorModal-input__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    @include flexbox;
    @include flexJustify;
    @include flexAlign;
}

.mobileCalculatorModal-input__img {
    // @include flexItem;
}

.calculatorModal-input__buttons {}

.mobileCalculatorModal-input__buttons {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    @include flexbox(column); // @include flexItem;
}

.calculatorModal-input__button,
.mobileCalculatorModal-input__button {
    display: block;
    border: none;
    background: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    .fa {
        color: $primaryColor;
    }
}

.mobileCalculatorModal-input__button {
    @include flexItem(1 1 50%);
    padding: 0;
    &:first-of-type {
        border-bottom: 1px solid #dddddd;
    }
}

.calculatorModal-input__value,
.mobileCalculatorModal-input__value {
    font-style: italic;
    font-size: 2.875rem; // width: 40px;
    margin-right: 10px;
    text-align: center;
    @include flexbox;
    @include flexAlign(flex-end, end);
    @include flexJustify;
}

.mobileCalculatorModal-input__value {
    margin-right: 20px;
    font-size: 2.214rem;
}

.calculatorModal-input__labels,
.mobileCalculatorModal-input__labels {
    @include flexbox(column);
    @include flexJustify;
}

.calculatorModal-input__label,
.mobileCalculatorModal-input__label {
    line-height: 1.2;
}

.mobileCalculatorModal-results {
    @include flexbox;
    padding: 0 20px;
}

.mobileCalculatorModal-results__column {
    @include flexItem(1 1 auto);
    &:first-of-type {
        @include flexItem(0 0 75px);
        @include flexbox;
        @include flexAlign;
        margin-right: 20px;
    }
}

.mobileCalculatorModal-months{
  @include flexbox;
  padding: 0 20px 20px 20px;
  align-items: center;
  justify-content: center;
}

.calculatorModal-input__label--top,
.mobileCalculatorModal-input__label--top {
    font-size: 0.875rem;
    font-weight: $light;
}

.mobileCalculatorModal-input__label--top {
    font-size: 0.714rem;
}

.calculatorModal-input__label--bottom,
.mobileCalculatorModal-input__label--bottom {
    font-size: 1rem;
    font-weight: $semibold;
}

.mobileCalculatorModal-input__label--bottom {
    font-size: 0.857rem;
}

.calculatorModal-results {
    @include flexbox;
    @include flexAlign;
    @include flexJustify;
    padding: 0 25px;
}

.calculatorModal-results__column {
    @include flexItem(1 1 33%);
    @include flexbox(column);
}

.calculatorModal-results__graphic {
    @include flexbox(column);
    @include flexAlign;
    @include flexJustify;
    padding: 25px;
    position: relative;
    span {
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: 45px;
        text-align: center;
        color: #fff;
        font-size: 1rem;
        font-weight: $semibold;
    }
}

.calculatorModal-result {
    @include flexbox(column);
    @include flexAlign;
    @include flexJustify;
    padding: 20px 0;
    &:first-of-type {
        border-bottom: 1px solid #dddddd;
    }
}

.mobileCalculatorModal-result {
    padding: 20px 0;
}

.mobileCalculatorModal-results__graphic {
    position: relative;
    span {
        position: absolute;
        display: block;
        text-align: center;
        bottom: 8px;
        right: 0;
        left: 0;
        color: #fff;
        font-size: 0.714rem;
        font-weight: $semibold;
    }
    img {
        max-width: 100%;
    }
}

.mobileCalculatorModal-result--top {
    border-bottom: 1px solid #dddddd;
}

.calculatorModal-result__label,
.mobileCalculatorModal-result__label {
    font-size: 0.875rem;
}

.mobileCalculatorModal-result__label {
    line-height: 1;
    font-size: 0.714rem;
}

.calculatorModal-result__value,
.mobileCalculatorModal-result__value {
    font-size: 1.500rem;
    font-weight: $semibold;
}

.mobileCalculatorModal-result__value {
    line-height: 1.2;
    font-size: 1.357rem;
}

.calculatorModal-result--bottom,
.mobileCalculatorModal-result--bottom {
    .calculatorModal-result__value,
    .mobileCalculatorModal-result__value {
        font-size: 2.188rem;
        small,
        .small {
            font-size: 1rem;
        }
    }
}

.mobileCalculatorModal-result--bottom {
    .mobileCalculatorModal-result__value {
        font-size: 2.071rem;
    }
}

.calculatorModal-footer {
    border-top: 1px solid #dddddd;
    @include flexbox;
    @include flexJustify(space-between, justify);
    @include flexAlign(baseline);
    padding: 15px 20px;
}

.calculatorModal-footer__text {
    font-size: 1.125rem;
    color: #3f3f3f;
    font-weight: $semibold;
    span {
        font-size: 1.750rem;
        color: #d8b733;
    }
}

.mobileCalculatorModal-footer {
    @include flexbox(column);
    @include flexAlign;
    @include flexJustify;
    border-top: 1px solid #dddddd;
    background: #1ebad7;
    background-image: linear-gradient(to right, #db9b1f, #d9b81e);
    padding: 10px 20px;
    border-radius: 0 0 5px 5px;
    color: #fff !important;
    text-decoration: none !important;
}

.mobileCalculatorModal-footer__text {
    font-size: 1rem;
    text-align: center;
    span {
        font-size: 1.3rem;
        font-weight: $semibold;
    }
}

.mobileCalculatorModal-footer__call2action {
    font-size: 1rem;
    font-weight: $semibold;
}

a.calculatorModal-submitButton {
    display: inline-block;
    text-decoration: none !important;
    font-size: 1rem;
    font-weight: $semibold;
    background: #1ebad7;
    background-image: linear-gradient(to right, #db9b1f, #d9b81e);
    height: 40px;
    line-height: 38px;
    border: none;
    border-radius: 20px;
    color: #fff;
    padding: 0 30px;
    &.calculatorModal-moreInfoButton{
        background: #1ebad7;
        background-image: -webkit-linear-gradient(left, #1ebad7, #1fd1af);
        background-image: -o-linear-gradient(left, #1ebad7, #1fd1af);
        background-image: linear-gradient(to right, #1ebad7, #1fd1af);
    }
}

.mobileCalculatorModal-body{
  a.calculatorModal-submitButton{
    margin-bottom: 10px;
    width: 85%;
  }
}

.calculatorModal-hidden {
    @include flexItem;
    margin: 25px 0;
}
