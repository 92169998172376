@import "bootstrap/bootstrap";
@import "font-awesome/font-awesome";
$primaryColor: #909090;
$secondaryColor: #4d4d4d;
$light: 300;
$normal: 400;
// $semibold: 500;
$semibold: 700;
$breakpointSM: 576px;
$breakpointMD: 768px;
@import "mixins";
@import "partials/utilities";
@import "partials/typography";
@import "partials/layout";
@import "partials/nav";
@import "partials/footer";
@import "partials/carousel";
@import "partials/accordion";
@import "partials/customOl";
@import "partials/infoLogos";
@import "partials/bgContainer";
@import "partials/picturePreview";
@import "partials/download";
@import "partials/calculatorModal";