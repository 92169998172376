.bgContainer {
    @include flexbox;
    @include flexAlign;
    background: url("../img/img_woman-shower.jpg") no-repeat;
    background-size: cover;
    background-position: 75%;
    color: #fff;
    text-align: center;
    min-height: 100vh;
    padding-left: 30px;
    padding-right: 30px;
    .row {
        height: 100%;
        width: 100%;
    }
    div[class*="col-"] {
        @include flexbox(column);
        @include flexAlign;
        @include flexJustify;
    }
    h4,
    p {
        max-width: 100%;
    }
    h4 {
        margin-bottom: 15px;
        font-weight: $semibold;
        color: inherit;
        font-size: 2.250rem;
    }
    @media (min-width: $breakpointMD) {
        div[class*="col-"] {
            @include flexAlign(flex-start,
            start);
            text-align: left;
        }
    }
    @media (max-width: $breakpointMD - 1) {
        h4 {
            font-size: 1.714rem;
        }
    }
}

.bgContainer__logo {
    width: 90px;
    height: 90px;
    margin-bottom: 15px;
    @media (max-width: $breakpointMD - 1) {
        width: 45px;
        height: 45px;
    }
}